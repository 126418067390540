<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                @change="onSelectProject"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="班组">
              <a-select-pro
                class="form-control"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="leaderList"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="工种">
              <a-select-pro
                class="form-control"
                v-decorator="['workerType']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="workerTypeList"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input class="form-control" v-decorator="['idCard']" placeholder="请输入身份证号" allowClear></a-input>
            </a-form-item>
            <a-form-item label="工人姓名">
              <a-input
                class="form-control"
                v-decorator="['username']"
                placeholder="请输入工人姓名"
                allowClear
              ></a-input>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <span style="color: red;">特别说明:本报表为截止上一日的数据</span>
        <div class="queryView-actions">
          <a-button @click="exportConfirm">
            <a-icon type="export"></a-icon>
            导出
          </a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <ExportConfirm ref="ExportConfirm"></ExportConfirm>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectListByCard, getLeaderListByCard, getWorkerTypeList } from '@/service/getData'
import ASelectPro from '@/components/SelectPro.vue'
import ExportConfirm from './modules/ExportConfirm'
import fileDownload from 'js-file-download'

export default {
  components: {
    ASelectPro,
    ExportConfirm,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '姓名',
        width: '5%',
        align: 'center',
        dataIndex: 'username',
      },
      {
        title: '身份证',
        width: '10%',
        align: 'center',
        dataIndex: 'cardNumber',
      },
      {
        title: '籍贯',
        width: '10%',
        align: 'left',
        dataIndex: 'nativePlace',
      },
      {
        title: '年龄',
        width: '5%',
        align: 'center',
        dataIndex: 'age',
      },
      {
        title: '当前项目',
        width: '15%',
        align: 'left',
        dataIndex: 'projectName',
      },
      {
        title: '当前班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '当前工种',
        width: '6%',
        align: 'left',
        dataIndex: 'workerType',
      },
     /* {
        title: '当前项目考勤天数',
        width: '6%',
        dataIndex: 'totalDay',
      },*/
      /*{
        title: '累计应发金额',
        width: '6%',
        dataIndex: 'needAmount',
      },
      {
        title: '累计实发金额',
        width: '6%',
        dataIndex: 'actualAmount',
      },
      {
        title: '累计欠发金额',
        width: '6%',
        dataIndex: 'backAmount',
      },*/
      {
        title: '操作',
        width: '6%',
        align: 'center',
        customRender: (text, row, index) => {
          return (
            <div>
              <a
                class="row-action-btn"
                onClick={() => {
                  this.checkRow(row)
                }}
              >
                详情
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      projectList: [],
      leaderList: [],
      workerTypeList: [],
      exportConfirmParams: null,
    }
  },
  computed: {
    typesMap() {
      return {
        // workerKind: [
        //   { value: 0, label: '包工' },
        //   { value: 1, label: '点工' },
        // ],
      }
    },
  },
  methods: {
    resetAndQuery() {
      this.resetForm(this.form)
      this.initialQuery()
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = {
          ...this.getPageParams(_pageNo, _pageSize),
        }
        const projectName = this.projectList.find((item) => item.value === params.projectCode)?.label
        const leaderName = this.leaderList.find((item) => item.value === params.leaderId)?.label
        const res = await this.$axios.post('/report/evidence/chain/worker/list', params)
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.exportConfirmParams = {
          displayCondition: {
            projectName,
            leaderName,
            workerType: params.workerType,
            idCard: params.idCard,
            username: params.username,
            totalCount: this.totalCount,
          },
          condition: params,
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = await getLeaderListByCard({ projectCode })
    },

    checkRow(row) {
      this.$router.push({
        path: './detail',
        query: {
          cardId: row.cardNumber,
          leaderId: row.leaderId,
          projectCode: row.projectCode,
        },
      })
    },
    async exportConfirm() {
      if (!this.exportConfirmParams) {
        return this.$message.error('正在查询中...')
      }
      await this.$refs.ExportConfirm.start(this.exportConfirmParams)
      this.exportExcel()
    },
    async exportExcel() {
      const res = await this.$axios.post(
        '/report/evidence/chain/worker/export',
        {
          ...this.exportConfirmParams.condition,
          pageNo: 1,
          pageSize: this.exportConfirmParams.displayCondition.totalCount,
        },
        {
          interceptors: {
            response: false,
          },
          responseType: 'arraybuffer',
        }
      )
      fileDownload(res.data, '工友证据链-列表.xls')
    },
    async loadWorkerTypeList() {
      this.workerTypeList = await getWorkerTypeList()
    },
    async loadProjectList() {
      this.projectList = await getProjectListByCard()
    },
    async loadLeaderList() {
      this.leaderList = await getLeaderListByCard()
    },
    initialQuery() {
      this.loadWorkerTypeList()
      this.loadProjectList()
      this.loadLeaderList()
      this.doQuery(1)
    },
  },
  async mounted() {
    this.initialQuery()
  },
}
</script>

<style lang="less" scoped></style>
